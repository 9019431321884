<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-text-box-check</v-icon>生産完了申請</v-app-bar-title>
        <v-spacer></v-spacer>
        <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
        <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-row dense>
              <v-col>
                <v-switch label="申請済み" v-model="searchModel.yodobashiProductionCompleted" class="mx-2"></v-switch>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.releaseDateFrom"
                  type="date"
                  :rules="[rules.releaseDateFrom]"
                  label="発売日From"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.releaseDateTo"
                  type="date"
                  label="発売日To"
                  :rules="[rules.releaseDateTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.orderClosingDateFrom"
                  type="date"
                  :rules="[rules.orderClosingDateFrom]"
                  label="受注締日From"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.orderClosingDateTo"
                  type="date"
                  label="受注締日To"
                  :rules="[rules.orderClosingDateTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  class="mx-2"
                  label="JANコード"
                  dense
                  rows="1"
                  v-model="searchModel.jancode"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                ></v-textarea>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          :disabled="selectionRows.length === 0"
          icon="mdi-file-send"
          @click="onBtnDownload"
          v-if="allowedAction('C090102')"
          >申請書送付</tooltip-icon-button
        >
        <confirm-grid-dialog ref="confirmGrid" width="1100px" height="350px"></confirm-grid-dialog>
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical class="mx-2"></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <ag-grid-vue
          id="SuppliersConversionList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :columnTypes="columnTypes"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="(data) => data.jancode"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="SuppliersConversionInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <product-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :productCd="selectedRow != null ? selectedRow.productCd : null"
          :approval="false"
        ></product-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import ProductInfos from "./../../components/product/ProductInfos.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  NumericColumn,
  FullDateColumn,
  DateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { getDisplayDetails } from "../../models/ag-grid/helpers";
import Validation from "../../libs/validation";

export default {
  name: "ProductionCompletedApplication",
  data() {
    return {
      gridStyle: { height: "95%" },
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        suppressSizeToFit: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      validSearchForm: null,
      isRowSelectable: (rowNode) => !rowNode.data.yodobashiProductionCompleted,
      columnDefs: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
        { headerName: "商品名", field: "productName", pinned: "left", filterParams: IncludeFilter },
        {
          headerName: "商品ステータス",
          field: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoProductStatus.all() },
          valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
        },
        { headerName: "仕入先略称", field: "supplierName" },
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "受注締日", field: "orderClosingDate", type: "dpDateColumn" },
        { headerName: "発売日", field: "releaseDate", type: "dpDateColumn" },
        {
          headerName: "ヨドバシ生産完了登録済み",
          field: "yodobashiProductionCompleted",
          filter: "dpBooleanFilter",
          filterParams: { caption: { true: "登録済", false: "未登録" } },
          type: "dpCheckmarkColumn",
        },
      ],
      defaultCsvExportParams: {
        allColumns: true,
        onlySelectedAllPages: false,
      },
      rowData: [],
      detailSelected: false,
      infoMaximum: false,
      selectedPageSize: null,
      localeText: AG_GRID_LOCALE_JA,
      selectionRows: [],
      searchModel: {
        orderClosingDateFrom: null,
        orderClosingDateTo: null,
        releaseDateFrom: null,
        releaseDateTo: null,
        jancode: null,
        yodobashiProductionCompleted: false,
      },
      editDialog: { show: false, width: "800px" },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      rules: {
        maxLength: Validation.maxLength,
        isJancodeSearchList: Validation.isJancodeSearchList,
        orderClosingDateFrom: (value) => this.orderClosingDateFromRules(value),
        orderClosingDateTo: (value) => this.orderClosingDateToRules(value),
        releaseDateFrom: (value) => this.releaseDateFromRules(value),
        releaseDateTo: (value) => this.releaseDateToRules(value),
      },
    };
  },
  components: {
    AgGridVue,
    ProductInfos,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ConfirmGridDialog,
    ErrorGridDialog,
  },
  mounted() {
    this.$store.commit("ui/loading", true);
    this.onSearchClick();
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.jancode, this.gridApi, this.columnApi);
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridApi.exportDataAsCsv(allColumnIds);
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    onBtnInsert() {
      this.editModel = {
        createNew: true,
      };
      this.editDialog.show = true;
      if (this.$refs.excessDeficiencyEntry) this.$refs.excessDeficiencyEntry.onOpen(this.editModel);
    },
    onBtnUpdate() {
      this.editModel = {
        createNew: false,
      };
      this.editDialog.show = true;
      if (this.$refs.excessDeficiencyEntry) this.$refs.excessDeficiencyEntry.onOpen(this.editModel);
    },
    async onBtnDownload() {
      this.$refs.confirmGrid.open({
        title: "承認確定",
        columns: [
          {
            headerName: "JANCode",
            field: "jancode",
            pinned: "left",
          },
          { headerName: "タイトル", field: "title" },
          { headerName: "商品名", field: "productName" },
          {
            headerName: "ヨドバシ生産完了登録済み",
            field: "yodobashiProductionCompleted",
            type: "dpCheckmarkColumn",
          },
        ],
        records: this.selectionRows,
        onSubmit: async () => {
          try {
            this.loadingOn();
            const productCompleteds = {
              productCompleteds: this.selectionRows.map((r) => ({
                janCode: r.jancode,
                updateDatetime: r.updateDatetime,
              })),
            };

            const result = await this.$store.dispatch("yodobashi/create", productCompleteds);
            let errorMsg = {};
            let tmpErrorMsg = {};
            switch (result.data?.header.resultCode) {
              case ApiStatus.consts.SUCCESS:
                this.$dialog.notify.info(`選択された商品の生産完了申請を送付しました`, {
                  timeout: 2300,
                });
                this.onSearchClick();
                break;
              case ApiStatus.consts.ALREADY_CHANGED:
              case ApiStatus.consts.BUSINESS_ERROR:
                tmpErrorMsg = this.selectionRows.map(function (r) {
                  let tmp = null;
                  if (result.data.header.messages[r.jancode]) {
                    tmp = {
                      janCode: r.jancode,
                      errorMsg: result.data.header.messages[r.jancode]?.join(),
                    };
                  }
                  return tmp;
                });
                errorMsg = tmpErrorMsg.filter((r) => r != null);

                this.$refs.errorGrid.open({
                  title: "エラーメッセージ",
                  columns: [
                    {
                      headerName: "janCode",
                      field: "janCode",
                      pinned: "left",
                    },
                    { headerName: "エラーメッセージ", field: "errorMsg" },
                  ],
                  records: errorMsg,
                  onClose: async () => {
                    this.selectionRows = [];
                    this.onSearchClick();
                  },
                });
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("yodobashiProductionCompleted::onBtnDownload", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionDetailRows = [];
        var params = { ...this.searchModel };
        // JANコードを１行の文字列から配列(半角スペース区切り)へ変更
        params.jancode = this.searchModel.jancode
          ? this.searchModel.jancode
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        const response = await this.$store.dispatch("yodobashi/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }

        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "生産完了申請一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.productCompleteds).length === 0) {
          this.$dialog.warning({
            title: "生産完了申請一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(response.data.contents.productCompleteds);
        this.gridApi.forEachLeafNode((node) => {
          if (node.data.autoCheck) {
            node.setSelected(true);
          }
        });
        const allColumnIds = this.columnApi.getAllColumns().map((column) => column.colId);
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("YodobashiSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onPageSizeChanged() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    orderClosingDateFromRules(value) {
      if (value == null || this.searchModel.orderClosingDateTo == null) {
        return true;
      }
      if (moment(value, "YYYY-MM-DD").isAfter(this.searchModel.orderClosingDateTo)) {
        return "受注締日Toより前の日付を指定してください";
      }
      return true;
    },
    orderClosingDateToRules(value) {
      if (value == null || this.searchModel.orderClosingDateFrom == null) {
        return true;
      }
      if (moment(value, "YYYY-MM-DD").isBefore(this.searchModel.orderClosingDateFrom)) {
        return "受注締日Fromより後の日付を指定してください";
      }
      return true;
    },
    releaseDateFromRules(value) {
      if (value == null || this.searchModel.releaseDateTo == null) {
        return true;
      }
      if (moment(value, "YYYY-MM-DD").isAfter(this.searchModel.releaseDateTo)) {
        return "発売日Toより前の日付を指定してください";
      }
      return true;
    },
    releaseDateToRules(value) {
      if (value == null || this.searchModel.releaseDateFrom == null) {
        return true;
      }
      if (moment(value, "YYYY-MM-DD").isBefore(this.searchModel.releaseDateFrom)) {
        return "発売日Fromより後の日付を指定してください";
      }
      return true;
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.rowData = [];
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
};
</script>
